import {gapi} from 'gapi-script';
import {initClient} from './initClient';
import * as Sentry from '@sentry/react';


export const getContacts = async (targetEmails, targetNames, token) => {
  await initClient(token, 'people', 'v1');

  return new Promise((resolve, reject) => {
    gapi.client.people.people.connections.list({
      resourceName: 'people/me',
      personFields: 'phoneNumbers,names,emailAddresses',
      pageSize: 1000,
    }).then(({body}) => {
      const response = JSON.parse(body);

      if (!response.connections) {
        Sentry.captureException(new Error('getContacts: No connections found: ' + JSON.stringify({
            response,
            targetEmails,
            targetNames,
          }))
        );

        return resolve({
          success: false,
          message: 'No connections found: ' + JSON.stringify(response),
          contacts: [],
        });
      }

      // Filter by email or name
      const filteredConnections = (response.connections || []).filter(connection => {
        const emails = connection.emailAddresses?.map(email => email.value.toLowerCase());
        const names = connection.names?.map(name => name.displayName.toLowerCase());

        // Check if any name matches
        const hasNameMatch = names && targetNames.some(targetName =>
          names.includes(targetName.toLowerCase())
        );

        // Check if any email matches
        const hasEmailMatch = emails && targetEmails.some(targetEmail =>
          emails.includes(targetEmail.toLowerCase())
        );
        return hasNameMatch || hasEmailMatch;
      });
      resolve({
        success: true,
        contacts: filteredConnections,
      });
    }).catch(err => {
      reject(err);
    });
  });
};
