import { Button, CheckboxField, Flex, Text, TextAreaField, TextField, View } from '@aws-amplify/ui-react';
import React, { useEffect, useState } from 'react';
import { isGsmMessage, smsTemplate } from '../services/sms-builder';
import {
    MAX_SMS_CHARACTERS, MAX_SMS_CHARACTERS_UNICODE, REMEMBER_KEY, SENDER_NAME, TEMPLATE_KEY
} from '../constants';
import { useMetaConfig } from '../useMetaConfig';

const isMessageValid = (message) => {
    if (!message?.length) {
        return true;
    }
    if (isGsmMessage(message)) {
        return message?.length <= MAX_SMS_CHARACTERS;
    }
    return message?.length <= MAX_SMS_CHARACTERS_UNICODE;
};

function TemplateEditor({ onSubmit }) {
    const [showPreview, setShowPreview] = useState(false)
    const [remember, setRemember, removeRemember] = useMetaConfig({ keyName: REMEMBER_KEY, defaultValue: false, cloudBackup: true})
    const [template, setTemplate, removeTemplate] = useMetaConfig({ keyName: TEMPLATE_KEY, cloudBackup: true})
    const [senderName, setSenderName, removeSenderName] = useMetaConfig({ keyName: SENDER_NAME, cloudBackup: true})

    const messageOutput = smsTemplate(template, '10 April @ 11:30', 1.5, senderName);
    const hasContentError = !isMessageValid(messageOutput);

    useEffect(() => {
        onSubmit({ template, senderName, hasContentError })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [template, senderName, hasContentError])


    const clearPrefilledData = () => {
        removeRemember()
        removeTemplate()
        removeSenderName()
    }

    const renderPreview = () => {
        if (!showPreview) {
            return null
        }
        const isDesktop = window.innerWidth > 1000;

        return <View style={{
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            display: 'flex',
            flexDirection: 'column',
            padding: '50px 30px',
            background: 'white',
            opacity: 0.93,
            ...(isDesktop && {
                maxWidth: '600px',
                margin: '0 auto',
            })

        }}>
            <Text fontSize={'1.5rem'}>Preview</Text>
            <br/>
            <hr />
            <Text variation={"info"}
                  fontSize={'1rem'}
                  backgroundColor={hasContentError ? 'hsl(0, 75%, 85%)' : 'hsl(130, 60%, 90%)'}
                  paddingTop={'10px'}
                  paddingBottom={'10px'}
                  level={6}>"{messageOutput}"</Text>
            <br/>
            <hr />
            <Text variation={hasContentError ? 'error' : ''}
                  fontSize={'.9rem'}
                      level={6}>
                You current message characters length is
                <br/>
                {messageOutput.length} / {isGsmMessage(template) ? MAX_SMS_CHARACTERS : MAX_SMS_CHARACTERS_UNICODE}
            </Text>

            <hr />
            <hr />
            <Text fontSize={'.85rem'}>
                A message has up to 160 characters,<br/>if emojy or diacritics are used,<br/>the message's max length is 70 characters.
            </Text>
            <br/>
            <br/>
            <Button flex
                    variation="tertiary"
                    onClick={() => setShowPreview(false)}>
                Close Preview
            </Button>
       </View>
    }

    const renderEditor = () => {
        return <>
            <TextField
                alignItems={'stretch'}
                textAlign={'left'}
                autoComplete="off"
                label={<Flex textAlign="left">Sender</Flex>}
                placeholder="Type your or your company's name..."
                variation="quiet"
                width="100%"
                defaultValue={senderName}
                onChange={(e) => setSenderName(e.currentTarget.value)}
                onBlur={(e) => setSenderName((e.currentTarget.value || '').trim())}
            />
            <br/>
            <TextAreaField
                alignItems={'flex-start'}
                textAlign={'left'}
                autoComplete="off"
                label="Content"
                placeholder="Type your message..."
                rows="3"
                style={{ width: '100%' }}
                defaultValue={template}
                hasError={template?.length && hasContentError}
                errorMessage="Your message is too long, `Preview Message` to see the details"
                onChange={(e) => setTemplate(e.currentTarget.value)}
                onBlur={(e) => setTemplate((e.currentTarget.value || '').trim())}

            />
            <br/>
            <CheckboxField
                name="subscribe-controlled"
                value="yes"
                checked={remember}
                onChange={(e) => {
                    const nextValue = e.currentTarget.checked

                    if (!nextValue) {
                        const acknowledge = window.confirm('By unchecking this option, you will lose your prefill data. Are you sure?')
                        if (acknowledge) {
                            setRemember(false)
                            clearPrefilledData()
                        }
                    } else {
                        setRemember(nextValue)
                    }
                }}
                label={<Text variation={"info"}
                             fontSize={'.8rem'}
                             level={6}>Save SMS content for future use</Text>}
            />
            <br/>
                <Button variation="tertiary"
                        onClick={() => setShowPreview(true)}>
                Preview Message
            </Button>
        </>
    }

    return (
        <>
            {renderEditor()}
            {renderPreview()}
        </>);
}

export default TemplateEditor;
