import dayjs from 'dayjs';
import { gapi } from 'gapi-script';
import { findPhoneNumbersInText } from 'libphonenumber-js';
import { initClient } from './initClient';
import * as Sentry from '@sentry/react';


export function getTimeRange() {
    const today = dayjs();
    let timeMax;

    // TODO maybe use `.isoWeekday` instead of.weekday plugin
    // If today is Sunday, set timeMax to the next Sunday, otherwise to the upcoming Sunday
    if (today.weekday() === 7) {
        // If today is Sunday, get the next Sunday
        timeMax = today.add(1, 'week');
    } else {
        // If today is not Sunday, get the upcoming Sunday
        timeMax = today.weekday(7);
    }

    timeMax = timeMax
      .set('hour', 23)
      .set('minute', 59)
      .set('second', 59);

    return { timeMin: today, timeMax };
}

export function getUniqueEmails(eventsEntries) {
   try {
       return Array.from(new Set(eventsEntries.reduce((acc, {attendees}) => {
           if (attendees?.length) {
               const emails = attendees.map(({email, responseStatus}) => email)
               return [...acc, ...emails]
           }
           return acc
       }, [])))
   } catch (e) {
       console.error(e)
       Sentry.captureException(e);
   }
}

export function getUniqueNames(eventsEntries) {
    try {
        return Array.from(new Set(eventsEntries.reduce((acc, {summary}) => {
            const isNotPhoneNumber= !extractPhoneNrFromText(summary) // skip if phonenumber
            if (summary?.length && isNotPhoneNumber) {
                return [...acc, summary]
            }
            return acc
        }, [])))
    } catch (e) {
        console.error(e)
        Sentry.captureException(e);
    }
}

export function extractPhoneNrFromText(description = '') {
    const matches = findPhoneNumbersInText(description, 'RO').map(phone => phone.number);

    return matches[0]?.number;
}

export async function getEvents (calendarId, token, opts = {}) {
    await initClient(token, 'calendar', 'v3')

    const {timeMin, timeMax} = getTimeRange()

    console.log(`> getCalendarEntries: `, {
        calendarId: calendarId,
        maxResults: 250,
        timeMin: timeMin.toISOString(),
        timeMax: timeMax.toISOString(),
        singleEvents: true,
    });
    return new Promise((resolve, reject) => {
        gapi.client.calendar.events.list({
            calendarId: calendarId,
            maxResults: 250,
            timeMin: timeMin.toISOString(),
            timeMax: timeMax.toISOString(),
            singleEvents: true,
        }).then(({ body }) => {
            const {
                items,
            } = JSON.parse(body)

            resolve(items)
        }).catch(err => {
            reject(err)
        })
    } )
}


export async function getCalendars(token, refreshToken) {
    await initClient(token, 'calendar', 'v3')

    return new Promise((resolve, reject) => {
        gapi.client.calendar.calendarList.list({
            maxResults: 250,
        }).then(({ body }) => {
            const { items } = JSON.parse(body)
            resolve(items)
        }).catch(err => {
            reject(err)
        })
    } )
}
