import { useState, useEffect } from 'react';
import FirebaseService from './services/FirebaseService';
import * as Sentry from '@sentry/react';

export const useMetaConfig = ({ keyName, cloudBackup, globdal, defaultValue = null }) => {
    const [loaded, setLoaded] = useState(false);
    const [storedValue, setStoredValue] = useState(undefined);

    useEffect(() => {
        let isActive = true;

        const fetchCloudBackup = async () => {
            try {
                const val = await FirebaseService.getMetaConfigValue(keyName);
                console.log(`useMetaConfig: ${keyName} remote: ${val}`);

                if (isActive) {
                    setStoredValue(val);
                    setLoaded(true);
                }
            } catch (err) {
                console.error(`useMetaConfig: ${keyName} error: ${err}`);
                if (isActive) {
                    setLoaded(true);
                }
                Sentry.captureException(err);
            }
        };

        if (cloudBackup && !loaded) {
            fetchCloudBackup();
        } else if (!cloudBackup) {
            try {
                const localValue = JSON.parse(window.localStorage.getItem(keyName));
                console.log(`useMetaConfig: ${keyName} local: ${localValue}`);
                if (localValue !== null) {
                    setStoredValue(localValue);
                }
            } catch (err) {
                console.error(`useMetaConfig: ${keyName} local read error: ${err}`);
                window.localStorage.setItem(keyName, JSON.stringify(defaultValue));
                Sentry.captureException(err);
            }
        }

        if (global) {
            window[keyName] = storedValue;
        }

        return () => {
            isActive = false;
        };
    }, [keyName, cloudBackup, loaded]);

    const setValue = newValue => {
        try {
            window.localStorage.setItem(keyName, JSON.stringify(newValue));
            if (cloudBackup && newValue !== storedValue) {
                FirebaseService.updateMetaConfig({ [keyName]: newValue });
            }
            if (global) {
                window[keyName] = newValue;
            }
            setStoredValue(newValue);
        } catch (err) {
            console.error(`useMetaConfig: ${keyName} set error: ${err}`);
            Sentry.captureException(err);
        }
    };

    const clearValue = () => {
        try {
            window.localStorage.removeItem(keyName);
            if (cloudBackup) {
                FirebaseService.updateMetaConfig({ [keyName]: undefined });
            }
            if (global) {
                window[keyName] = undefined;
            }
        } catch (err) {
            console.error(`useMetaConfig: ${keyName} clear error: ${err}`);
        }
    };

    return [storedValue, setValue, clearValue, loaded];
};
