import React from 'react';

function TermsOfUse({ onClose }) {
  return (
    <div style={{position: 'relative'}}>
      <div style={{
        position: 'fixed',
        fontSize: '2rem',
        fontWeight: 'bolder',
        right: '1rem',
        top: '.75rem',
      }}
           onClick={() => onClose() }>&#215;</div>
      <div id="tos"  style={{
        top: '0',
        left: '0',
        width: '100%',
        height: '100vh',
        lineBreak: 'auto',
        textAlign: 'left',
        overflow: 'auto',
        backgroundColor: 'white',
        paddingBottom: '10rem',
      }}>
        <h2>Expanded Terms of Service for smscalendar.xyz</h2>

        <h4>1. Introduction</h4>
        Welcome to smscalendar.xyz, a comprehensive web application designed to streamline your scheduling and
        communication needs by integrating with widely-used services such as Google Calendar, Google Contacts, and your
        email system. Our platform is specifically tailored to enhance your productivity by allowing seamless scheduling
        of SMS messages for your appointments. By registering for and utilizing our services, you acknowledge and agree
        to be bound by these Terms of Service, which govern your use of our platform and its various features.

        <h4>2. Account and Service Use</h4>
        Account Registration: To fully access and utilize the functionalities of our service, you are required to create
        a personalized account. During this process, it is imperative that you provide information that is both accurate
        and current to ensure the effectiveness of the service. You bear the sole responsibility for maintaining the
        confidentiality and security of your account details, including your password.
        Service Use: Our service has been designed to integrate seamlessly with your Google Calendar and Contacts. This
        integration is central to our ability to schedule SMS messages corresponding to your appointments. By using our
        service, you grant us express permission to access your Google services, which is a necessary component of the
        functionality we provide.

        <h4>3. User Responsibilities</h4>
        Data Accuracy: As a user of our platform, you carry the responsibility of ensuring that all data you input, or
        import into our system, remains accurate and up to date. This is crucial for the optimal performance of our
        services.
        Prohibited Use: You are strictly prohibited from utilizing our service for any illegal purposes. This includes,
        but is not limited to, activities that infringe upon the rights of others, as well as the creation or
        distribution of unauthorized or unsolicited messages.

        <h4>4. Data Storage and Security</h4>
        In our commitment to service reliability and efficiency, we maintain records of scheduled SMS messages and
        pertinent data. These records are crucial for ensuring timely and accurate delivery of services. We adopt
        industry-standard measures to safeguard your data against unauthorized access or breaches, thereby ensuring the
        security and integrity of your information.

        <h4>5. Termination and Modification</h4>
        We reserve the full right to alter or discontinue any aspect of our service at our sole discretion. This
        includes the right to terminate your account in cases where there is a breach or violation of these Terms. We
        will endeavor to notify you of any significant changes to our service, but it remains your responsibility to
        review these Terms regularly.

        <h4>6. Limitation of Liability</h4>
        smscalendar.xyz shall not be held liable for any indirect, incidental, special, consequential, or punitive
        damages that arise from or are related to your use of our services. This limitation of liability is a
        fundamental element of the basis of the bargain between smscalendar.xyz and you, the user.
      </div>
    </div>
  );
}

export default TermsOfUse;
