import {
  Button,
  Divider,
  Flex,
  Heading,
  Image,
  Link,
  Loader,
  Menu,
  MenuItem,
  Placeholder,
  SwitchField,
  Text
} from '@aws-amplify/ui-react';
import dayjs from 'dayjs';
import React, {useEffect, useMemo, useState} from 'react';
import {googleLogout, useGoogleLogin} from '@react-oauth/google';

import SendSMSFlow from './components/SendSMSFlow';
import {
  ABOUT_QPARAM,
  DEBUGGER_MODE_KEY,
  FAST_FORWARD_AUTH_QPARAM,
  GOOGLE_OAUTH_SCOPES,
  PRIVACY_POLICY_QPARAM,
  TERMS_OF_SERVICE_QPARAM
} from './constants';
import {useAuthentication} from './contexts/AuthenticationContext';
import './App.css';
import {ReactComponent as GoogleIcon} from './icons/google.svg';


import weekday from 'dayjs/plugin/weekday'
import isTomorrow from 'dayjs/plugin/isTomorrow'
import isToday from 'dayjs/plugin/isToday'
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'
import weekOfYear from 'dayjs/plugin/weekOfYear'
import {useMetaConfig} from './useMetaConfig';
import TermsOfUse from './components/TermsOfUse';
import PrivacyPolicy from './components/PrivacyPolicy';
import About from './components/About';
// import utc from 'dayjs/plugin/utc'

dayjs.extend(weekday)
dayjs.extend(isToday)
dayjs.extend(isTomorrow)
dayjs.extend(isSameOrBefore)
dayjs.extend(weekOfYear)
// dayjs.extend(utc)

// https://developers.google.com/calendar/api/guides/auth

const whatsappMsgEncoded = `I+am+interested+in+SMScalendar.xyz%21`
const whatsappPhoneNumber = '0040748049693'

/**
 * Checks for 'ff' flag and automatically authenticates.
 */

async function getGoogleTokens(code) {
  const url = `${process.env.REACT_APP_AUTH_SERVICE}/google/token`;
  const opts = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({code})
  }
  const response = await fetch(url, opts);
  console.log(response)
  return response.json();
}

function App() {
  const [loading, setLoading] = useState()
  const [revision, setRevision] = useState()
  const {authentication, onSignIn} = useAuthentication()
  const [isDebuggerMode, setIsDebuggerMode] = useMetaConfig({
    keyName: DEBUGGER_MODE_KEY,
    defaultValue: false,
    cloudBackup: true,
    global: true,
  });
  const [showFullscreenModal, setShowFullscreenModal] = useState(null)
  const googleLogin = useGoogleLogin({
    flow: 'auth-code',
    scope: GOOGLE_OAUTH_SCOPES,
    onSuccess: async (codeResponse) => {
      const tokens = await getGoogleTokens(codeResponse.code)
      await onAuthResponse(tokens)
      setLoading(false)
    },
    onError: errorResponse => {
      setLoading(false)
      console.error(`Google login error: ${errorResponse}`)
    },
    onNonOAuthError: (nonOAuthError) => {
      setLoading(false)
      console.error(`NonGoogle login error: ${nonOAuthError}`)
    }
  });

  const postAuthorizationIncrement = () => {
    let searchParams = new URLSearchParams(window.location.search)
    const url = new URL(window.location.href);

    if (searchParams.has(FAST_FORWARD_AUTH_QPARAM)) {
      url.searchParams.delete(FAST_FORWARD_AUTH_QPARAM);
      window.history.pushState(null, document.title, url);
      document.querySelector('.o-auth-btn').click()
    } else if (searchParams.has(PRIVACY_POLICY_QPARAM)) {
      url.searchParams.delete(PRIVACY_POLICY_QPARAM);
      window.history.pushState(null, document.title, url);
      setShowFullscreenModal('pp')
    } else if (searchParams.has(TERMS_OF_SERVICE_QPARAM)) {
      url.searchParams.delete(TERMS_OF_SERVICE_QPARAM);
      window.history.pushState(null, document.title, url);
      setShowFullscreenModal('tos')
    }
    else if (searchParams.has(ABOUT_QPARAM)) {
      url.searchParams.delete(ABOUT_QPARAM);
      window.history.pushState(null, document.title, url);
      setShowFullscreenModal('about')
    }
  }

  const onLogout = () => {
    googleLogout();
    window.location.reload();
  }

  useEffect(() => {
    bumpRevision()
    setup()
    postAuthorizationIncrement()
  }, [])

  const bumpRevision = () => setRevision(Date.now())

  const showFullscreenContent = (type) => {
    setShowFullscreenModal(type)
  }

  const setup = () => {
    // LocalServiceWorkerRegister()
  }

  const onAuthResponse = async (tokens) => {
    await onSignIn(tokens)
  }

  const menu = useMemo(() => {
    return (
      <div style={{
        position: 'fixed',
        top: '10px',
        right: '10px',
      }}>
        <Menu menuAlign="end" size="small">
          <SwitchField label={'Debugging Mode'}
            // isChecked={isDebuggerMode}
                       defaultChecked={isDebuggerMode}
                       isDisabled="true"
                       trackCheckedColor="red"
                       onChange={(event) => {
                         setIsDebuggerMode(event.target.checked);
                       }}/>
          <Divider/>
          <MenuItem onClick={onLogout}>
            Logout
          </MenuItem>
        </Menu>
      </div>
    )
  }, [])

  const fullscreenModalContent = useMemo(() => {
    if (!showFullscreenModal) {
      return null
    } else if (showFullscreenModal === 'tos') {
      return <TermsOfUse onClose={() => showFullscreenContent(null)}/>
    } else if (showFullscreenModal === 'pp') {
      return <PrivacyPolicy onClose={() => showFullscreenContent(null)}/>
    }
    else if (showFullscreenModal === 'about') {
      return <About onClose={() => showFullscreenContent(null)}/>
    }
  }, [showFullscreenModal])

  const renderAuthorized = () => {
    if (!authentication) {
      return null
    }

    return (<>
        {menu}
        <SendSMSFlow
          key={revision}
          onCompletion={bumpRevision}
        />
      </>
    )
  }

  const renderUnauthorized = () => {
    if (authentication) {
      return null
    }

    return <>
      <Image
        width={500}
        alt="flowers-image"
        src={'/flowers.png'}
      />
      <Button className="o-auth-btn" style={{marginTop: '20px'}}
              disabled={loading}
              onClick={() => {
                setLoading(true)
                googleLogin()
              }}>
        <div style={{width: '20px', marginRight: '10px'}}>
          {!loading && <GoogleIcon/>}
          {loading && <Loader/>}
        </div>
        Sign in with Google
      </Button>

      {renderFooter()}
    </>
  }

  const renderFooter = () => {
    return <Text variation={"info"} fontSize=".85rem">
      <br/>
      Would you like to join the closed BETA?<br/>
      Reach out over:<br/>
      <Link textDecoration="none"
            href={`mailto:bogdam.maier1@gmail.com?subject=${whatsappMsgEncoded}`}>
        Email
      </Link>{`, `}
      <Link textDecoration="none"
            href="https://twitter.com/BogdanM0">
        Twitter
      </Link>{' or '}
      <Link textDecoration="none"
            href={`https://wa.me/${whatsappPhoneNumber}?text=${whatsappMsgEncoded}`}>
        Whatsapp
      </Link>
    </Text>
  }

  return (

    <div className="App">
      {fullscreenModalContent}

      {loading ? <Placeholder size="small"/> : <Placeholder size="small" style={{visibility: 'hidden'}}/>}
      <Flex width="100%"
            direction="column"
            alignItems="center"
            gap="1rem"
      >
        <Heading level={3}>
          <Text variation="primary" color="#176b68">
            SMSCalendar.xyz
            <Text variation="success" fontSize=".9rem"
                  style={{textAlign: 'end', marginRight: '-20px', marginTop: "-8px"}}>
              BETA
            </Text>
          </Text>
        </Heading>
      </Flex>

      <Text variation="secondary"
            fontSize=".8rem" marginTop="-12px">
        Notify your appointments over SMS
      </Text>
      <Divider orientation="horizontal" style={{visibility: 'hidden', margin: '10px'}}/>

      <Flex
        direction="column"
        alignItems="center"
        style={{height: '75vh'}}
      >
        <div className="App-content">
          {renderAuthorized()}
          {renderUnauthorized()}
        </div>
      </Flex>

      <div alignItems="center" style={{fontSize: '.74rem'}}>
        <Link textDecoration="none"
              onClick={() => showFullscreenContent('about')}>
          About
        </Link>
        {` | `}
        <Link textDecoration="none"
              onClick={() => showFullscreenContent('tos')}>
          Terms of Use
        </Link>
        {` | `}
        <Link textDecoration="none"
              onClick={() => showFullscreenContent('pp')}>
          Privacy Policy
        </Link>

      </div>
      <br/>
      {!fullscreenModalContent && <Text variation="info"
                                        style={{
                                          fontSize: '12px',
                                          position: 'sticky',
                                          bottom: '20px',
                                        }}>Version: {process.env.REACT_APP_GIT_SHA || 'Development'}</Text>}
    </div>)
}

export default App
