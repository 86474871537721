import {gapi} from 'gapi-script';

export const initClient = async (token, api, version) => {
  return new Promise((resolve, reject) => {
    if (gapi[api]) {
      resolve(true)
    }

    return gapi.load('client:auth2', () => {
      gapi.client.setToken({ access_token: token });
      // gapi.auth.setToken({ access_token: token });
      gapi.client.load(api, version, () => resolve(true))
    })
  })
};
