import React from 'react';



function PrivacyPolicy({ onClose }) {
  return (
    <div style={{position: 'relative'}}>
      <div style={{
        position: 'fixed',
        fontSize: '2rem',
        fontWeight: 'bolder',
        right: '1rem',
        top: '.75rem',
      }}
      onClick={() => onClose() }>&#215;</div>
      <div id="pp" style={{
          top: '0',
          left: '0',
          width: '100%',
          height: '100vh',
          lineBreak: 'auto',
          textAlign: 'left',
          overflow: 'auto',
          backgroundColor: 'white',
          paddingBottom: '10rem',
      }}>
        <h1>Expanded Privacy Policy with GDPR Compliance</h1>

        <h4>1. Introduction</h4>
        <p>At smscalendar.xyz, we are deeply committed to safeguarding your privacy. This Privacy Policy provides a detailed overview of our practices regarding the collection, use, and sharing of your personal information. Our policies are designed to comply with the General Data Protection Regulation (GDPR) and reflect our dedication to protecting your privacy.</p>

        <h4>2. Information Collection and Use</h4>
        <p>Data Collected: We collect essential information from your integrated Google services, such as calendar events and contact details. This collection is integral to our ability to schedule SMS messages effectively.
          Use of Information: The information we collect is utilized for the purpose of scheduling SMS messages, managing your contacts, and improving overall service functionality. This use is in line with the consent you provide and is essential for the services we offer.</p>

        <h4>3. Data Sharing and Disclosure</h4>
        <p>Third-Party Service Providers: In certain instances, we may collaborate with third-party service providers to augment our service offerings. In such cases, we may share your data with these providers, strictly for the purposes of enhancing our service delivery.
        Legal Requirements: We may be compelled to disclose your information if required by law or in response to valid requests by public authorities.</p>

        <h4>4. User Rights and GDPR Compliance</h4>
        <p>Access and Control: In accordance with GDPR, you are entitled to access, rectify, or erase your personal data that we hold.
        Data Portability: You possess the right to obtain your data in a structured, commonly used, and machine-readable format.
        Consent and Withdrawal: Your consent forms the basis of our processing of your personal data. You have the unequivocal right to withdraw this consent at any point, without affecting the lawfulness of processing based on consent before its withdrawal.</p>

        <h4>5. Data Security</h4>
        <p>Our commitment to data security is unwavering. We employ appropriate technical and organizational measures to protect your personal data from unauthorized or unlawful processing, accidental loss, destruction, or damage.</p>

        <h4>6. Changes to Privacy Policy</h4>
        <p>We recognize that privacy practices evolve over time. As such, we reserve the right to update this Privacy Policy periodically. We will provide notification of any significant changes to our policy.</p>

        <h4>7. Contact Information</h4>
        <p>For any inquiries or concerns regarding this policy or your personal data, please do not hesitate to contact us at bogdan.maier1&nbsp;@&nbsp;gmail.com.</p>

      </div>
    </div>
  );
}

export default PrivacyPolicy;
