import { createContext, useContext, useState } from 'react';
import FirebaseService from '../services/FirebaseService';
import * as Sentry from "@sentry/react";


export const ServiceContext = createContext(undefined);

export const ServiceProvider = ({ children }) => {
    const [firebase, setFirebase] = useState()
    const [lastError, setLastError] = useState()

    const initAfterAuth = async (authData) => {
        try {
            // setup firebase with authenticated user
            const fb = await FirebaseService.init(authData)
            setFirebase(fb)
            await fb.updateMetaConfig({sc_last_auth: authData});
        } catch (error) {
            Sentry.captureException(error);
            console.log(error)
        }
    }

    const value = {
        initAfterAuth,
        setLastError,
        lastError,
        firebase,
    };

        return <ServiceContext.Provider
            value={value}>
            {children}
        </ServiceContext.Provider>;
}

export const useServices = () => {
    const context = useContext(ServiceContext);

    if (context === undefined) {
        throw new Error("`useAuthentication` must be used within a ServiceContext");
    }

    return context;
}



