import dayjs from 'dayjs';
import FirebaseService from './FirebaseService';
import {getEvents, extractPhoneNrFromText, getUniqueEmails, getUniqueNames} from './GCalendar';
import { getContacts } from './GContacts';
import * as Sentry from '@sentry/react';

export async function buildEventAndSubjectsData(calendarId, skippedEventIds, token) {
        const eventsEntries = await getEvents(calendarId, token)

        // filter already notified events
        const eventsRequiringSMSScheduling = eventsEntries.filter(e => !skippedEventIds.includes(e.id))

        // identify names and emails
        const emails = getUniqueEmails(eventsRequiringSMSScheduling);
        const names = getUniqueNames(eventsRequiringSMSScheduling);

        // get contacts / connections
        const { contacts, success, message } = await getContacts(emails, names, token)

        if (!success) {
            console.warn(message)
        }

        // build sms subjects data by email (event invitation), or name in subject matching event summary
        const subjectsInfo = Object.fromEntries([
          ...emails.map(email => [email, {}]),
          ...names
            .map(name => [name, {}])
        ])

        // populate telefon of SMS subjects of users
        // invited from connections/contacts in the calendar UI
        // users whom names match event summary(title) from contacts
        // (!if contact has no email it cannot be added to calendar invitation)
        contacts.forEach(({emailAddresses = [], names = [], phoneNumbers = []}) => {
            emailAddresses.forEach((emailAddress) => { // TODO iterate further if the user has multiple  contacts with same email?
                const {value: email} = emailAddress
                if (JSON.stringify(subjectsInfo[email]) === '{}') {
                    subjectsInfo[email] = {
                        phoneNumbers: Array.from(new Set(phoneNumbers.map(({canonicalForm}) => canonicalForm))),
                    }
                }
            });

            names.forEach((namez) => {
                const {displayName: name} = namez
                if (JSON.stringify(subjectsInfo[name]) === '{}') {
                    subjectsInfo[name] = {
                        phoneNumbers: Array.from(new Set(phoneNumbers.map(({canonicalForm}) => canonicalForm))),
                    }
                }
            });
        })

        eventsRequiringSMSScheduling.forEach((event, index) => {
           try {
               const {attendees = [], start} = event
               const hasContact = Boolean(attendees.length)
               const email = event.attendees?.[0]?.email
               const summary = event.summary
               const numberFromSummary = extractPhoneNrFromText(event.summary)

               // Note: we override contacts phone numbers with description phone number
               // the key is the email, name  or timestamp
               const eventKey = hasContact
                 ? email
                 : !numberFromSummary // if it's a name in the summary
                    ? summary
                    : start.dateTime || start.date
               const contactNumbers = numberFromSummary
                 ? [numberFromSummary]
                 : hasContact
                   ? subjectsInfo?.[email]?.phoneNumbers
                   : subjectsInfo?.[summary]?.phoneNumbers

               subjectsInfo[eventKey] = {
                   phoneNumbers: contactNumbers,
               }
           } catch (err) {
               console.error(err)
               FirebaseService.log({
                   in: JSON.stringify( {
                       calendarId,
                       eventsEntries,
                   }),
                   error: JSON.stringify(err.messsage)
               }, {
                   updated: dayjs().format(),
                   method: 'buildEventAndSubjectsData',
               })
             Sentry.captureException(err);
           }
        })
        return {
            eventsEntries: eventsRequiringSMSScheduling,
            subjectsData: subjectsInfo,
        }
}
