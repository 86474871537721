import React, { useState } from 'react';
import { Alert, Button, Card, Heading, Image, Text, useTheme } from '@aws-amplify/ui-react';
import { useAuthentication } from '../contexts/AuthenticationContext';
import { useServices } from '../contexts/ServiceContext';

Toasts.propTypes = {

};

function Toasts() {
    const { tokens } = useTheme();
    const { reauthorize, authentication } = useAuthentication()
    const [showPopup, setShowPopup] = useState()
    const { lastError } = useServices()
    const approvedScope = authentication?.scope ?? '';

    const errors = {
        hasMissingContactsPermissions: !approvedScope.includes('contacts'),
        hasMissingCalendarPermissions: !approvedScope.includes('calendar'),
        noInternetConnection: !window.navigator.onLine,
        lastError: lastError, // TODO keep?
    }

/*
TODO
   useEffect(() => {
        window.addEventListener('offline', function(e) {
                // Network disconnected
            }
        );

        window.addEventListener('online', function(e) {
            // Network connected
        }
    })*/

    const renderPopup = () => {
        if (!showPopup) return

        return <Card key="popup" id="popup"
          style={{
            position: 'absolute',
            top: '3vh',
            height: '80vh',
            width: '87vw',
            left: '3vh',
            background: '#ffffffd9'
          }}>
                <Heading level={2}
                         color={tokens.colors.error}
                         backgroundColor={tokens.colors.background.error}>
                    Action required
                </Heading>
                <br/>
                <br/>
                <Text>
                    During the Google Authentication process<br/>
                    you are required to check <br/>
                    these missing permissions.
                    <br/>
                </Text>
                <br/>
                <br/>
                <Image
                    style={{height: '50vh'}}
                    src={'/required_oauth_checks.png'}
                />
                <br/>
                <br/>
                <br/>
                <Button variation="primary"
                        onClick={() => reauthorize()}>
                    Allow missing permissions
                </Button>
                <Button variation="secondary"
                        onClick={() => setShowPopup(false)}>
                    Cancel
                </Button>
            </Card>
    }
    return <div style={{
        marginTop: '20px',
        marginLeft: '-20px',
        marginRight: '-20px',
    }}>
        {renderPopup()}
        {Object
            .entries(errors)
            .map(([error, hasError]) => <>
                {
                    error === 'hasMissingContactsPermissions' && hasError &&
                    <Alert key="hasMissingContactsPermissions"
                           id="hasMissingContactsPermissions"
                           variation="warning"
                           hasIcon={false}
                    >
                        Missing "Contacts" permission
                    </Alert>
                }
                { error === 'hasMissingCalendarPermissions' && hasError &&
                    <Alert key="hasMissingCalendarPermissions"
                           id="hasMissingCalendarPermissions"
                           variation="error"
                           hasIcon={false}>
                        Missing "Calendar" permission<br/>
                        Click <Button variation="link"
                                onClick={() => setShowPopup(true)}>here</Button> to fix it
                    </Alert>
                }
                { error === 'noInternetConnection' && hasError &&
                    <Alert key="hasNoInternetConnection"
                           id="hasNoInternetConnection"
                           variation="error"
                           hasIcon={false}>
                        You have no Internet connection<br/>
                        Please fix that then refresh page.
                    </Alert>
                }
            </>)}
    </div>
}

export default Toasts;
