import { createContext, useContext, useState } from 'react';
import { FAST_FORWARD_AUTH_QPARAM } from '../constants';
import { incrementAuthorization } from '../services/GOAuth';
import { useServices } from './ServiceContext';
import {googleLogout} from '@react-oauth/google';

export const AuthenticationContext = createContext(undefined);


export const AuthenticationProvider = ({ children }) => {
    const { initAfterAuth } = useServices();
    const [authentication, setAuthentication] = useState(null)

    const reauthorize = async () => {
        console.log('> reauthorize')
        await incrementAuthorization((response) => {
            const url = new URL(window.location.href);
            url.searchParams.set(FAST_FORWARD_AUTH_QPARAM, 'true');

            window.location.assign(url.search);
        }, (e) => {
            alert('Something went wrong, try again!')
            // error:   "popup_blocked_by_browser"

        });
        googleLogout();
    };

    const onSignIn = async (authData) => {
        // idpiframe_initialization_failed	initialization of the Google Auth API failed (this will occur if a client doesn't have third party cookies enabled)
        // popup_closed_by_user	The user closed the popup before finishing the sign in flow.
        //   access_denied	The user denied the permission to the scopes required
        // immediate_failed

        setAuthentication(authData)
        await initAfterAuth(authData)
    }
    const getToken =  () => authentication.access_token

    const value = {
        authentication,
        reauthorize,
        getToken,
        onSignIn,
    };

        return <AuthenticationContext.Provider
            value={value}>
            {children}
        </AuthenticationContext.Provider>;
}

export const useAuthentication = () => {
    const context = useContext(AuthenticationContext);

    if (context === undefined) {
        throw new Error("`useAuthentication` must be used within a AuthenticationContext");
    }

    return context;
}



