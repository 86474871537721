import {Button, Card, Flex, Heading, View} from '@aws-amplify/ui-react';
import dayjs from 'dayjs';
import * as PropTypes from 'prop-types';
import React, {useEffect, useState} from 'react';
import {useServices} from '../contexts/ServiceContext';

export const WeekAnalytics = ({onHide}) => {
  const [weekData, setWeekData] = useState([]) // tODO refactor
  const {firebase} = useServices()
  const isDesktop = window.innerWidth > 1000;

  useEffect(() => {
    const getData = async () => {
      const wData = await firebase.getWeeklyActivity()
      const weeklyDataWithNoTimeStamp = Object.entries(wData)
        .filter(([key, value]) => key !== 'lastUpdated')
        .map(([key, value]) => value)
      setWeekData(weeklyDataWithNoTimeStamp)
    }

    getData().catch(err => console.log(err))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const renderLogs = (entries, checkKey) => {
    if (!entries.length) return <div>No data</div>;

    return entries.map((entry, index) => {
      return <div key={index}>
        <div>
          {
            entry.error
              ? (<>
                <div><b>Error</b> on appointment</div>
                <div>For appointment @ {dayjs(entry.startDate).format('DD MMMM@HH:mm')}</div>
                <div style={{maxWidth: '80vw', overflow: 'hidden',  whiteSpace: 'break-spaces' }}>
                  {entry['message']}
                </div>
              </>)
              : (<>
                <div>Scheduled SMS @ {dayjs(entry.scheduledAt).format('DD MMMM@HH:mm')}</div>
                <div>Appointment: {entry.startDate}</div>
              </>)
        }
        <br/>
      </div>
    </div>
    })
  }

  return (<View style={{
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: 'flex',
    flexDirection: 'column',
    padding: '50px 30px',
    background: 'white',
    opacity: 0.93,
    ...(isDesktop && {
      maxWidth: '600px',
      margin: '0 auto',
    })
  }}>
    <Flex direction="column" justifyContent={'space-around'} width={'100%'}>
      <Heading level={6}>Week Analytics</Heading>

      <Card maxHeight="70vh" overflow="scroll">
        <pre>{renderLogs(weekData)}</pre>
      </Card>

      <Button
        display="flex"
        shrink="0"
        alignSelf="stretch"
        size="large"
        variation="primary"
        children="Primary Button"
        onClick={() => onHide()}>
        Done
      </Button>
    </Flex>
  </View>)
}

WeekAnalytics.propTypes = {
  logs: PropTypes.arrayOf(PropTypes.any),
  failures: PropTypes.any,
  onCompletion: PropTypes.func,
};
