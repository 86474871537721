import React from 'react';

function About({onClose}) {
  return (
    <div style={{position: 'relative'}}>
      <div style={{
        position: 'fixed',
        fontSize: '2rem',
        fontWeight: 'bolder',
        right: '1rem',
        top: '.75rem',
      }}
           onClick={() => onClose()}>&#215;</div>
      <div id="tos" style={{
        top: '0',
        left: '0',
        width: '100%',
        height: '100vh',
        lineBreak: 'auto',
        textAlign: 'left',
        overflow: 'auto',
        backgroundColor: 'white',
        paddingBottom: '10rem',
      }}>
        <h2>About SMSCalendar.xyz</h2>
        Welcome to SMSCalendar.xyz. Our web application integrates directly with Google Calendar to enhance your event
        management through timely SMS notifications. Designed to ensure you and your contacts are always informed about
        upcoming events, our service is a practical solution for busy schedules.
        <br/>
        <br/>
        By connecting with your Google Calendar, we access event details to send personalized reminders. Our system
        scans your contacts for each event, extracting phone numbers to send SMS notifications about 24 hours in
        advance. This efficient approach significantly reduces missed appointments and improves communication.
        <br/>
        <br/>
        Our commitment to improvement is ongoing. We actively update SMSCalendar.xyz, incorporating new features and
        responding to user feedback. We welcome your input and are always available to assist with any queries or
        suggestions to enhance your experience.
      </div>
    </div>
  );
}

export default About;
