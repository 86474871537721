import dayjs from 'dayjs';
import React, {useEffect, useState} from 'react';
import {Loader, PhoneNumberField, ScrollView, SelectField, Text, useTheme, View} from '@aws-amplify/ui-react';
import {COUNTRY_CODES} from '../constants';
import {useAuthentication} from '../contexts/AuthenticationContext';
import {useServices} from '../contexts/ServiceContext';
import {buildEventAndSubjectsData } from '../services/MessageService';


export function SubjectsPreview({calendarId, onSubmit}) {
  const {firebase} = useServices()
  const {tokens} = useTheme();
  const [isLoading, setIsLoading] = useState(true)
  const [events, setEvents] = useState(null)
  const [subjectsMap, setSubjectsMap] = useState({})
  const [weeklyActivity, setWeeklyActivity] = useState()
  const {getToken} = useAuthentication()
  const previousSelection = undefined // TODO read from cache possible previous update
  const [sentSMSs, setSentSMSs] = useState()

  useEffect(() => {
    onSubmit({
      subjectsMap,
      events,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subjectsMap])

  useEffect(() => {
    firebase.getWeeklyActivity()
      .then(weeklyData => {
        const weeklyDataWithNoTimeStamp = Object.entries(weeklyData)
          .filter(([key, value]) => key !== 'lastUpdated')
          .map(([key, value]) => value)
        setWeeklyActivity(weeklyDataWithNoTimeStamp)
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getToken])

  useEffect(() => {
    if (weeklyActivity && isLoading) {
      const onWeeklyActivity = async () => {
        const weeklyActivityEntries = weeklyActivity
        const alreadyNotifiedEventsIds = weeklyActivityEntries
          .filter(({ status }) => status === 'scheduled')
          .map(({ eventId }) => eventId)
        const formattedDates = weeklyActivityEntries.map((e) => {
          return `${dayjs(e.startDate).format('DD/MM hh:mm')}  ${e.phoneNumbers[0]}`
        }) || []

        const {subjectsData, eventsEntries} = await buildEventAndSubjectsData(
          calendarId,
          alreadyNotifiedEventsIds,
          getToken()
        );

        setSentSMSs(formattedDates)
        setIsLoading(false)
        setEvents(eventsEntries)
        setSubjectsMap(subjectsData)
      }

      onWeeklyActivity()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [events, weeklyActivity, isLoading])


  const onManualPhoneNumber = ({phone = '', country = '+40'}, email) => {
    subjectsMap[email] = {
      ...subjectsMap[email],
      phoneNumbers: [
        `${country}${phone}`,]
    }
  }

  const renderNoFurtherEvents = () => {
    const hasNoAppointments = !Object.entries(subjectsMap).length && !isLoading

    if (hasNoAppointments) {
      return <Text variation="warning">All your appointments have been notified</Text>
    }
    return null
  }

  const renderAlreadySentSMS = () => {
    if (sentSMSs?.length) {
      return (
        <>
          <Text variation="tertiary" backgroundColor="#24a19c1f">
            A number of {sentSMSs.length} SMSs
            have been sent already this week
          </Text>
          <ul>
            {sentSMSs.map(dateStr => <li key={dateStr}>{dateStr}</li>)}
          </ul>
        </>
      )

    }
    return null
  }

  return (
    <div className={'subjects-preview'}>
      <Text>Subjects Preview</Text>
      <br/>
      {isLoading && <Loader variation="linear"/>}
      <ScrollView maxHeight="380px" width="80vw"
                  templateColumns="1fr"
      >
        {renderNoFurtherEvents()}
        <br/>

        {!isLoading && Object.entries(subjectsMap)
          .map(([key, {phoneNumbers = []}], i) => (<React.Fragment>
            <View style={{
              ...(i % 2 === 1 && {backgroundColor: tokens.colors.neutral['10']}),
            }}>
              <View backgroundColor={previousSelection || (phoneNumbers?.length === 1 && phoneNumbers[0]) ? 'transparent' : '#fae4e4'}>
                <Text lineHeight="1rem"
                      fontSize=".9rem"
                      marginTop=".6rem"
                      isTruncated>
                  {/* from iso date format + offset */}
                  {key.includes('+') ?  dayjs(key).format('DD/MM/YYYY HH:mm') : key }
                </Text>
              </View>
              {phoneNumbers.length ?
                <SelectField keys={key} labelHidden
                             placeholder={`Select phone number`}
                             size="small"
                             variation="quiet"
                             defaultValue={previousSelection || phoneNumbers[0]}>
                  {phoneNumbers.map((option) => (<option key={`${key}-${option}`}
                                                         value={option}>
                    {option}
                  </option>))}
                </SelectField> : null}

              {phoneNumbers.length === 0 ? <PhoneNumberField
                label={'Phone number'}
                labelHidden
                defaultDialCode="+40"
                variation="quiet"
                size="small"
                placeholder={`Add missing number`}
                onChange={(event) => {
                  onManualPhoneNumber({phone: event.target.value}, key)
                }}
                dialCodeList={COUNTRY_CODES}
                onDialCodeChange={(event) => {
                  onManualPhoneNumber({country: event.target.value}, key)
                }}
              /> : null}
            </View>
            <br/>
          </React.Fragment>))}


        {renderAlreadySentSMS()}

      </ScrollView>
    </div>);
}
