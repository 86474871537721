import dayjs from 'dayjs';
import { initializeApp } from 'firebase/app';
import {
    getFirestore, collection, doc, setDoc, getDoc,updateDoc, arrayUnion, serverTimestamp,
} from 'firebase/firestore';
import { getAnalytics } from 'firebase/analytics';
import { getAuth, signInWithCredential, GoogleAuthProvider } from '@firebase/auth';
import { invalidateOAuth } from './GOAuth';
import * as Sentry from '@sentry/react';


export const firebaseConfig = {
    apiKey: 'AIzaSyAOOKh3vSUU8jNZFT25eG_aW8uNxjzrQao',
    authDomain: 'sms-api-348815.firebaseapp.com',
    projectId: 'sms-api-348815',
    storageBucket: 'sms-api-348815.appspot.com',
    messagingSenderId: '51895342690',
    appId: '1:51895342690:web:5f6bbfdd305ee09cf3a33b',
    measurementId: 'G-WQ50C79Z63'
};

const COL_USERS = 'users';
const COL_USERS_META = 'users_meta_configs';

/**
 * Service responsible with de communication
 */
class FirebaseService extends EventTarget {
    readyFlag = false;
    app = null;
    analytics = null;
    auth = null;
    remoteConfig = null;
    db = null;
    firebaseUser = null

    async init(tokens) {
        try {
            // iniutialize firebase
            this.app = initializeApp(firebaseConfig);
            this.analytics = getAnalytics(this.app);
            this.auth = getAuth(this.app);

            // auth firebase
            const credential = GoogleAuthProvider.credential(null, tokens.access_token);
            this.firebaseUser = await signInWithCredential(this.auth, credential);

            if (tokens.access_token) {
                // firestore
                this.db = getFirestore();

                this.ready();
                return this
            } else {
                throw new Error('Firebase auth failed');
            }
        } catch (err) {
            console.error(err);
            Sentry.captureException(err);

            if (err.code === "auth/invalid-credential") {
                await invalidateOAuth()

                throw err;
            }

        }

        return this;
    }

    getProfile() {
        return this.firebaseUser.user;
    }

    async getWeeklyActivity() {
        try {
            const currentWeek = `${dayjs().week()}`
            const docRef = await doc(this.db, COL_USERS, this.firebaseUser.user.email);
            const snap = await getDoc(docRef);

            const data = snap.data();

            return snap.exists() && data[currentWeek]
              ? data[currentWeek]
              : {};
        } catch (err) {
            console.error(err)
            Sentry.captureException(err);
        }
    }

    async appendWeekActivity(payload) {
        try {
            const currentWeek = `${dayjs().week()}`
            const email = this.firebaseUser.user.email
            const docRef = await doc(this.db, COL_USERS, email);
            const snap = await getDoc(docRef);
            const data = snap.data();
            const existingWeekData = data?.[currentWeek] || {};
            existingWeekData.lastUpdated = serverTimestamp();

            Object.values(payload).forEach(messageData => {
                const eventId = messageData.eventId;

                existingWeekData[eventId] = {
                    ...existingWeekData[eventId],
                    ...(existingWeekData[eventId]?.status === 'scheduled'
                      ? {}
                      : messageData) // what has been scheduled should not be overwritten
                };
            });

            const weekData = {
                [currentWeek]: existingWeekData
            }

            if (!snap.exists()) {
                await setDoc(docRef, weekData, { merge: true });
            } else {
                await updateDoc(docRef, weekData);
            }
        } catch (err) {
            console.error(err)
        }
    }

    async log(payload, metaDetails) {
        try {
            const col = await collection(this.db, COL_USERS)
            const d = doc(col, this.firebaseUser.user.email);

            const data = {
                logs: arrayUnion({
                    payload,
                    timestamp: (new Date()).toISOString(),
                    ...metaDetails
                })
            }

            await setDoc(d, data, { merge: true });
        } catch (err) {
            console.error(err)
        }
    }

    async updateMetaConfig(payload) {
        await this.waitForReady();

        try {
            const col = await collection(this.db, COL_USERS_META)
            const d = doc(col, this.firebaseUser.user.email);
            const snap = await getDoc(d);
            const data = snap.data() || {};

            const newMetaData = {
                ...data,
                ...payload,
            }

            if (!snap?.exists()) {
                await setDoc(d, newMetaData, { merge: true });
            } else {
                await updateDoc(d, newMetaData);
            }
        } catch (err) {
            console.error(err)
            Sentry.captureException(err);
        }
    }

    async getMetaConfigValue(key) {
        await this.waitForReady();

        try {
            const col = await collection(this.db, COL_USERS_META)
            const d = doc(col, this.firebaseUser.user.email);
            const snap = await getDoc(d);
            const data = snap.data();

            return data?.[key];
        } catch (err) {
            console.error(err)
        }
    }

    waitForReady() {
        return new Promise(resolve => {
            const checkReady = () => {
                if (this.isReady()) {
                    resolve();
                } else {
                    setTimeout(checkReady, 100); // Check every 100ms
                }
            };
            checkReady();
        });
    }

    async getUser() {
        try {
            const col = collection(this.db, COL_USERS);
            const d = doc(col, this.firebaseUser.user.email);
            const docSnap = await getDoc(d);

            const data = docSnap.data();
            if (data) {
                return data;
            }
            return {} // DEFAULT_USER_OBJECT;
        } catch (err) {
            console.error(err)
        }
    }

    ready() {
        this.readyFlag = true;
    }

    isReady() {
        return this.readyFlag;
    }


}

const service = new FirebaseService();

export default service;
