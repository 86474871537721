import React from 'react';
import ReactDOM from 'react-dom';
import { AmplifyProvider } from '@aws-amplify/ui-react';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import { GoogleOAuthProvider } from '@react-oauth/google';
import App from './App';
import { AuthenticationProvider } from './contexts/AuthenticationContext';
import { ServiceProvider } from './contexts/ServiceContext';
import reportWebVitals from './reportWebVitals';

import '@aws-amplify/ui-react/styles.css';
import './index.css';

if (process.env.NODE_ENV === 'production') {
    Sentry.init({
        dsn: 'https://37622a163974434bbcbcc64f67e9d481@o454207.ingest.sentry.io/6358017',
        integrations: [new BrowserTracing()],

        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: 1.0,
    });
}

window.parseJWT = (token) => {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
}

ReactDOM.render(<React.StrictMode>
    <GoogleOAuthProvider clientId={process.env.REACT_APP_GAPI_CLIENT_ID}>
    <AmplifyProvider>
        <ServiceProvider>
            <AuthenticationProvider>
                {/*<BrowserRouter>*/}
                <App/>
                {/*</BrowserRouter>,*/}
            </AuthenticationProvider>
        </ServiceProvider>
    </AmplifyProvider>
    </GoogleOAuthProvider>
</React.StrictMode>, document.getElementById('root'));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
