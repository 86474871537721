import { Button, Flex, Heading, Text } from '@aws-amplify/ui-react';
import * as PropTypes from 'prop-types';
import React from 'react';

export const Report = ({ successes, failures, pending, onCompletion }) => {
    return <Flex direction="column" justifyContent={'space-around'} width={'100%'}>
        <Heading level={6}>Report</Heading>
        <br/>
        <br/>
        <Text>Successful queued messages: {successes}</Text>
        <Text>Successful pending messages: {pending}</Text>
        <Text>Failed queued messages: {failures}</Text>
        <br/>
        {failures ||failures.length > 0 ? <>
              <Text variation="warning">There was an error</Text>
            <Text variation="warning">sending some SMSs</Text>

        </> : null
        }
        <Button
            display="flex"
            shrink="0"
            alignSelf="stretch"
            size="large"
            variation="primary"
            children="Primary Button"
            onClick={() => onCompletion()}
        >Done</Button>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
    </Flex>;
}

Report.propTypes = {
    logs: PropTypes.arrayOf(PropTypes.any),
    failures: PropTypes.any,
    onCompletion: PropTypes.func,
};
