import { DF_LABS_SMS_API_KEY } from '../constants';

export const sendSms = (body) => {
    const url = `${process.env.REACT_APP_SMS_API_URL}/v1/send?t=${DF_LABS_SMS_API_KEY}` // TODO process.env
    const headers = new Headers();
    headers.append("Content-Type", "application/json;charset=UTF-8");
    headers.append("Access-Control-Allow-Headers", "Origin, Content-Type, Accept, Access-Control-Allow-Origin");
    headers.append('Access-Control-Allow-Origin', window.location.origin);

    // debugger
    // const d = new Date("2023-11-22T17:15:00+02:00")
    // body.scheduledAtTS = d.toISOString()
    // body.scheduledAt = d.getTime()

    const requestOptions = {
        method: 'POST',
        redirect: 'follow',
        mode: 'cors',
        body: JSON.stringify(body),
        headers
    };

    return fetch(url, requestOptions)
        .then(async (response) => {
            const resp = await response.json()

            if (resp.error) throw resp

            delete body.error
            delete body.errorTS
            delete body.message

            if (!body.scheduledAt) {
                body.scheduledAt = new Date().getTime()
                body.scheduledAtTS = new Date().toISOString()
            }

            return {
                ...body,
                ...resp,
                smsId: resp.id,
                status: 'scheduled',
                success: true,

            }
        })
        .catch(error => {
            console.error('error:', error);
            if (error?.message?.startsWith("Scheduling was been prevented due to, scheduledAtTS is in the past:")) {
                return {
                    ...body,
                    ...error,
                    status: 'error',
                    errorTS: new Date().toISOString(),
                }
            }

            return {
                ...body,
                message: JSON.stringify(error.message),
                status: 'error',
                success: false,
            }
        });
}
